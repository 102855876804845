.registerBackground {
    background-color: #358282;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerTwoBlocks {
    border-radius: 5px;
    /* width: 1200px; */
    /* height: 800px; */
    /* background-color: orange; */
    display: flex;
    /* gap: -10px; */
    /* padding: 10px; */
}

.registerBlock1 {
    background-color: white;
    padding: 20px;
    flex: 0 0 auto;
}

.registerBlock2 {
    width: 400px;
    background-color: white;
    padding: 20px;
    flex: 0 0 auto;
}
.registerSaveButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 98%;
    padding: 5px;
}

.registerError {
    color: red;
    padding-left: 20px;
}

.registerCountryCode {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    width: 40px;
    height: 70px;
}

.registerMessage {
    text-align: center;
    padding: 10px;
}

.registerCGV {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registerCGV a {
    transform: translate(-55px, -10px);
}
