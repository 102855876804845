.cardParameter{
  width:36vw;
  /* width: ; */
  /* margin-right: 40px; */
  margin-right: 5px;
  margin-left: 5px;
  height: min-content;
  border: solid 1px #E7E7E7;
  /* box-shadow: 5px 5px 15px 5px grey; */
  margin-top:20px;
}
.rowInput{
  margin-right: 15px;
  margin-left: 15px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleParameter{
  margin-left: 25px;
}
.headerParameter{
  width: 100%;
  background-color: #E5E5E5;
  padding-top: 10px;
  padding-bottom: 10px;
}
.cardParameterPassword{
  margin-right: 37vw;
  border: solid 1px #E7E7E7;
  width:36vw;
  margin-top:30px;
}

.cardParameterPassword .MuiFormControl-root .MuiFormHelperText-root{
  color: red;
}

.cardCategoryParameter{
  width: 73vw;
  border: solid 1px #E7E7E7;
  margin-top: 25px;
}
.titlePasswordParameter{
  margin-left: 25px;
  color: #444D59;
}
.categoriesParameter{
  /* width: 100%; */
  margin-left: 25px;

  /* margin-left: 100px; */
}