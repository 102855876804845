/* # # # # # # # # # # CATS # # # # # # # # # #  */

.catContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.catCardText {
    position: absolute;
    color: white;
    font-weight: 600;
    font-size: 25px;
    word-wrap: break-word;
    /* background-color: #dddadad8; */
    /* filter: brightness(130%); */
    /* color: red; */
    /* transform: translate(-50%, -50%); */
}

.catContainer .catCard .removeOrEditCategory .orderSuppressIcon {
    background-color: #f9fafe;
    height: 35px;
    width: 35px;
    border-radius: 25px;
    color: #444d59;
    /* height: fit-content; */
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.catContainer .catCard .removeOrEditCategory {
    display: flex;
    /* flex-direction: row; */
}

.catCard {
    position: relative;
    width: 250px;
    height: 180px;
    border: solid 2px #e5e5e5;
    display: flex;
    justify-content: center;
    background-position: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    background-size: cover;
    font-size: 18px;
    /* background-image: linear-gradient(); */
    /* filter: brightness(90%); */
    /* color: #505050; */
    color: white;
    transition: all 0.1s;
    background-color: #f3f3f3;
}

.catCardAddCategory {
    position: relative;
    width: 250px;
    height: 180px;
    border: solid 2px #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    /* justify-content: end; */
    background-position: center;
    /* align-items: center; */
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    background-size: cover;
    font-size: 18px;
    /* background-image: linear-gradient(); */
    /* filter: brightness(90%); */
    /* color: #505050; */
    color: #444d59;
    transition: all 0.1s;
    background-color: #f3f3f3;
}
.catCard:hover {
    transform: scale(1.03);
    transition: all 0.1s;
}

.categoryAllContainer .titlePage {
    width: fit-content;
    margin-bottom: 25px;
}

.categoryAllContainer .MuiPaper-root {
    box-shadow: 0px 4px 5px 0px #9f9f9f40;
}

/* # # # # # # # # # # ARTICLES # # # # # # # # # #  */

.categoryTitle {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 40px;
}

/* # # # # # # # # # # OPTIONS # # # # # # # # # #  */
.menuTitle {
    color: #aeaeae;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 4px;
}

.optionsContainer {
    padding: 10px 50px 30px 50px;
}

.optionsLine {
    display: flex;
    align-items: center;
    gap: 5px;
}

.optionsInputLine {
    display: flex;
    /* border-top: solid 1px #d0d0d0; */
    padding: 2px;
    align-items: center;
    gap: 8px;
}

.optionsInputLine > div {
    border-bottom: solid 1px #d0d0d0;
    width: 230px;
    padding: 3px;
}

.optionsInputLine > input {
    width: 230px;
    padding: 3px;
    border: 0;
    border-bottom: solid 1px #d0d0d0;
}

.optionIcon {
    cursor: pointer;
    transition: all 0.1s;
}
.optionIcon:hover {
    transition: all 0.1s;
    transform: scale(1.1);
}
