.ArticleModalContainer {
    padding: 20px;
    /* background-color: orange; */
    min-height: 100px;
    /* max-width: 400px; */
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ArticleModalWrapper {
    /* border: solid 1px red; */
    /* height: 0; */
    padding: 30px;
    padding-left: 55px;
    margin-top: 10px;
    margin-bottom: -25px;
    /* animation: dropdown 3s; */
}

.ArticleModalButtonLine {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.ArticleModalText {
    margin-bottom: 20px;
}

.ArticleModalButton {
    background-color: #444d59;
    border-radius: 5px;

    width: 100px;
    text-align: center;
}
.ArticleModalButton:hover {
    background-color: #6e7c8d;
}

.ArticleModalRowButton {
    display: flex;
    flex-direction: row;
}

.ArticleModalRowSchedules {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ArticleModalWrapperTextField {
    margin-right: 25px;
}

.ArticleModalAlreadyExistantInput {
    width: 150px;
    margin-left: 30px;
}

.ArticleModalDelete {
    font-size: 25;
    color: 'red';
    margin-right: 15px;
}

.ArticleModalContainerButtonCreate {
    width: 100%;
    flex-direction: column;
    display: flex;
}

.ArticleButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}
.ArticleModalButtonCreate {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 15px;
}

.ArticleModalAddScheduleContainer {
    margin-top: 35px;
}

.ArticleModalButtonAdd {
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ArticleModalError {
    /* min-width: 500px; */
    color: red;
    font-size: 14px;
    text-align: left;
    /* margin-top: 13px;
    margin-right: 10px; */
}

.ArticleModalTitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.ArticleModalTitle {
    width: 70%;
    font-size: 18px;
    color: black;
    font-weight: 600;
    margin-left: 15px;
}
.ArticleModalClose {
    margin-right: 15px;
}
.ArticleModalClose .MuiSvgIcon-root {
    cursor: pointer;
}
