.optionListAllContainer .titlePage {
    width: fit-content;
    margin-bottom: 25px;
}

.optionListAllContainer .catCardAdd {
    position: relative;
    width: 250px;
    height: 180px;
    border: solid 2px #e5e5e5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    background-size: cover;
    font-size: 18px;
    /* color: #505050; */
    color: black;
    font-size: 40px;
    transition: all 0.1s;
    background-color: #f3f3f3;
}

.optionListAllContainer .catCardAdd:hover {
    transform: scale(1.03);
    transition: all 0.1s;
}
.optionsContainer .optionsLine .optionDisabled {
    color: #e8e7e8;
    cursor: default;
    transition: none;
    transform: none;
}

.optionsContainer .optionIcon {
    color: #444d59;
}

.optionListAllContainer .removeOrEditCategory {
    display: flex;
}

.optionListAllContainer .removeOrEditCategory .orderSuppressIcon {
    background-color: #f9fafe;
    height: 35px;
    width: 35px;
    border-radius: 25px;
    color: #444d59;
    /* height: fit-content; */
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.optionListAllContainer .removeOrEditCategory {
    display: flex;
    /* flex-direction: row; */
}

.optionListAllContainer .catCardText {
    color: #4f6787;
}
