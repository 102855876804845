.loginBackground {
    background-color: #358282;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginBox {
    width: 300px;
    margin-top: -17%;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
}

.loginBox input {
    border: 0px;
    border-bottom: 1px solid black;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    width: 100%;
    height: 40px;
}

.loginBox input:hover {
    border-bottom: 2px solid black;
}
.loginBox input:focus {
    outline: none;
    border-bottom: 2px solid #3f50b5;
}

.signInButton {
    background-color: #358282;
    color: white;
    width: 100%;
    border: 0px;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s;
}
.signInButton:hover {
    background-color: #2d3980;
}
