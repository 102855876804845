.LogoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px;
    justify-content: center;
    margin-top: 8px;
}

.LogoContainer img {
    width: 170px;
    /* height: 170px; */
    object-fit: contain;
    margin-top: 10px;
    border-radius: 5px;
}
.LogoContainer > div {
    font-size: 20px;
    /* color: white; */
    text-align: center;
}

.menu-item .MuiListItemText-root span {
    color: #928e8e;
    font-size: 16px;
    font-weight: 400;
}

.menu-item .active .MuiListItemText-root span {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
}
.MuiDrawer-root .MuiPaper-root {
    background-color: #ffffff;
}
.menuTitleStore {
    margin-top: 17px;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 18px;
}

.imgLogoMenuContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.containerAllMenu {
    background-color: white;
    height: 100%;
}
