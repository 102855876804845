.ra-input .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.MuiSwitch-root .Mui-checked .MuiSwitch-thumb {
    color: #444d59;
}

.MuiSwitch-Thumb {
}

.MuiSwitch-root .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #444d5980;
}

.settingVRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 15px;
}

.settingV2Buttons {
    display: flex;
}

.settingVName {
    width: 200px;
}
