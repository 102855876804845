.autoCompleteAccount {
    width: 290px;
    max-width: 290px;
    margin-bottom: -20px;
    padding-bottom: 25px;
}

.allContainerAccountEdit .columnAccount {
    width: unset;
    background-color: #efefef;
}

.AddressAutoCompleteAccount {
    display: flex;
    align-items: center;
    height: 88px;
    margin-bottom: -20px;
    padding-bottom: 25px;
}

.AddressAutoCompleteAccount .css-2b097c-container {
    width: 450px;
}

.allContainerAccountEdit .MuiPaper-rounded {
    box-shadow: none;
}

.allContainerAccountEdit .rowAccountContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rowFirstCard {
    font-weight: 400;
    font-size: 16;
    color: #424242;
    margin-left: 20px;
}

.rowSecondCard {
    font-weight: 400;
    font-size: 16;
    color: #424242;
}

.allContainerAccountEdit .containerCroppAll {
    margin-left: 35px;
}

.rowFirstCardSecondColumn {
    font-weight: 400;
    font-size: 16;
    color: #424242;
    margin-right: 10vw;
}

.allContainerAccountEdit .separatorAccount {
    height: 22px;
    background-color: #efefef;
}

.allContainerAccountEdit .cardAccount {
    flex-direction: column;
    border-radius: 10px;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: white;
    /* max-width: 81vw; */
    margin: auto;
}

/* # # # # # # # # # IMAGES # # # # # # # # # # # # # # # # #  */

.accountImg {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: white;
    margin: auto;
}

.accountImgBlock {
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* justify-content: center; */
    align-items: center;
}

.accountImgPrev {
    width: 300px;
    height: 150px;
    /* margin: 25px; */
    object-fit: contain;
    border: black solid 1px;
}

.accountImagePlaceHolder {
    width: 300px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 1px rgb(123, 123, 123);
}

.accountTitleButton {
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
}

.accountImgCropped {
    border: solid red 1px;
}

/* # # # # # # # # # # # # # # # # # # # # # # # # # # MAP  */

.mapAndAddress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mapAndAddress > div:first-child {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gmapsStorePoint {
    width: 3px;
    height: 3px;
    background-color: black;
}

.gmapsStoreIcon {
    width: 60px;
    height: 60px;
    transform: translate(-28px, -60px);
}

.allContainerAccountEdit .containerCroppAll .MuiFormControl-root div {
    background-color: white;
}

.allContainerAccountEdit .simple-form .MuiCardContent-root {
    padding: 0px;
}

.allContainerAccountEdit .cardParameterPassword {
    background-color: white;
    width: auto;
    border-radius: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 0;
}

.allContainerAccountEdit .cardParameterPassword .rowInput {
    justify-content: flex-start;
}

.allContainerAccountEdit .cardParameterPassword .headerParameter {
    background-color: white;
}

.allContainerAccountEdit .saveButton {
    background-color: #efefef;
}

.allContainerAccountEdit .saveButton .MuiToolbar-root {
    background-color: #efefef;
}

.allContainerAccountEdit .cardParameterPassword .changePasswordOption {
    font-weight: 500;
    font-size: 16px;
    color: #424242;
    margin-left: 15px;
    text-decoration: underline;
}

.rowFieldAccount {
    margin-top: 20px;
    margin-right: 15px;
    margin-left: 15px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.rowInputAccount {
    margin-right: 15px;
    margin-left: 15px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.separationMark {
    margin-left: 15px;
    margin-right: 20px;
}
.minWidthAccountLAbel {
    font-weight: 500;
    font-size: 16px;
    color: #424242;
    min-width: 170px;
    margin-right: 45px;
}

.allContainerAccountEdit .rowFieldAccount .MuiTypography-root {
    font-size: 16px;
    color: #424242;
    font-weight: 400;
}

.allContainerAccountEdit .titleAccount {
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0px;
    font-weight: 19px;
}

.titleAccount span {
    color: #444d59;
    font-size: 22px;
    font-weight: 500;
    margin-left: 35px;
}
.accountBigText {
    font-size: 25px;
}

.rowInputAccount .hideLabelClass .MuiFormLabel-root {
    z-index: 0;
}
.rowInputAccount .autoCompleteAccount .MuiInputBase-root input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.rowInputAccount .autoCompleteAccount .MuiInputBase-root input[type='number'] {
    -moz-appearance: textfield;
}

.changePasswordOption {
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    color: blue;
}
