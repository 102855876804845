.containerHistory{
  margin-left: 35px;
  margin-right: 35px;
}

.containerHistory .MuiTable-root .first{
margin: auto;
}

.containerAllHistoryContainer .MuiTable-root .MuiTableBody-root{
  background-color: #F9FAFE;
}

.containerAllHistoryContainer .titlePage {
  margin-bottom: 35px;
}

.containerAllHistoryContainer .MuiToolbar-regular{
  background-color: #EFEFEF;
}

.containerAllHistoryContainer .MuiPaper-root .MuiTablePagination-root .MuiToolbar-regular{
  background-color: #F9FAFE;
}