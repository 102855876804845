.fieldStatusContainer {
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 10px;
  padding: 3px;
  max-width: 90px;
}
.fieldStatusContainer span {
  white-space: nowrap;
  color: white;
  font-weight: bold;
  font-size: 11px;
  text-overflow: ellipsis;
}
