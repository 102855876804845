.inputContainer {
    width: 422px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: grey; */
}

.inputDialog {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid grey;
    outline: 0;
    font-size: 1.3rem;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    margin: 30px;
    text-align: center;
}
.inputContainer .MuiInputBase-root {
    /* min-width: 300px; */
    background-color: #f3f3f3;
    margin-bottom: 25px;
}

.inputContainer .MuiDialogTitle-root .MuiTypography-root {
    font-size: 16px;
    font-weight: 400;
    color: #444d59;
}

.inputContainer .MuiDialogTitle-root .MuiFormControl-root label {
    font-size: 16px;
    font-weight: 300;
    color: #928e8e;
}

.inputContainer .MuiDialogTitle-root .MuiFormControl-root input {
    font-size: 16px;
    font-weight: 300;
    color: #444d59;
}

.inputContainer .MuiFormControl-root {
    margin-bottom: 30px;
}

.inputContainer .MuiDialogTitle-root {
    padding-left: 0;
    padding-right: 0;
    width: inherit;
}

.inputContainer .bodyModal .saveButton .MuiButtonBase-root {
    margin-right: 0px;
}

.inputContainer .bodyModal .saveButton .MuiButtonBase-root:hover {
    background-color: #444d59;
}

.inputContainer .bodyModal .saveButton .Mui-disabled {
    background-color: #e8e7e8;
}

.inputContainer .actionModal .MuiSvgIcon-root {
    cursor: pointer;
}

.actionModal {
    padding: 20px;
    padding-top: 10px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
}

.bodyModal {
    width: -webkit-fill-available;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 21px;
}

.ModalMessageContainer {
    padding: 20px;
    /* background-color: orange; */
    min-height: 100px;
    max-width: 400px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ModalMessageButtonLine {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.ModalMessageText {
    margin-bottom: 20px;
}

.ModalMessageButton {
    background-color: #444d59;
    border-radius: 5px;

    width: 100px;
    text-align: center;
}
.ModalMessageButton:hover {
    background-color: #6e7c8d;
}
.ModalNonApprouve {
    padding: 10px;
}
.ModalNonApprouve_Info {
    font-weight: bold;
}
.ModalNonApprouve_Button {
    background-color: #444d59;
    border-radius: 5px;
    width: 160px;
    text-align: center;
    float: right;
}
.ModalNonApprouve_Button:hover {
    background-color: #6e7c8d;
}
