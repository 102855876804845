.rowSchedule{
  width:100%;
  /* border-bottom: 2px solid rgba(28,110,164,0.38); */
  background-color: white;
  /* padding: 15px; */
  /* margin-bottom: 80px; */
  border-bottom: 2px solid #E9E9E9;
  border-top: 2px solid #E9E9E9;
  padding-bottom: 15px;
  padding-top: 15px;
}

.rowScheduleFirst{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width:100%;
  border-bottom: 2px solid #E9E9E9;
  /* border-bottom: 2px solid rgba(28,110,164,0.38); */
  background-color: white;
  /* padding: 15px; */
  /* margin-bottom: 80px; */
  padding-bottom: 15px;
  padding-top: 15px;
}

.rowScheduleLast{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width:100%;
  border-top: 2px solid #E9E9E9;
  background-color: white;
  /* padding: 15px; */
  /* margin-bottom: 80px; */
  padding-bottom: 15px;
  padding-top: 15px;
}

.allContainerSchedule .MuiToolbar-root{
  background-color: #E9E9E9;
}
.allContainerSchedule .saveButton {
  background-color: #E9E9E9;
  margin-top: 15px;
  padding-bottom: 15px;
}

.allContainerSchedule .MuiPaper-root {
  box-shadow: none;
}

.scheduleTimeAlreadyPresent{
  margin: 10px;
}

.scheduleRowButton{
  display: flex;
  flex-direction: row;
}
.rowContainerSchedule{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  /* width: fit-content; */
}
.scheduleButtonCreate{
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  align-content: flex-end;
}

.scheduleButtonCreate .MuiButtonBase-root{
  background-color: #444D59;
}
.scheduleButtonAdd{
  margin-top: 15px;
}
.scheduleDelete{
  font-size: 25;
  color:'red';
  margin-right: 15px;
}
.scheduleIconContainer{
  /* border: 0.2px black solid;
  border-radius: 35px; */
  position: absolute;
  right: 74px;
  /* display: flex;
  justify-content: flex-end; */
}

.scheduleIconContainer .MuiSvgIcon-root{
  cursor: pointer;
}

.scheduleOpenTimeContainer{
  margin-left: 60px;
}

.scheduleAlreadyExistantInput{
  width:150px;
  margin-left: 30px;
}
.scheduleRowSchedules{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.scheduleWrapperTextField{
  margin-right: 25px;
}
.scheduleContainerButtonCreate{
  width: 100%;
  flex-direction: column;
  display: flex;
}

.addScheduleContainer{
  margin-top: 35px;
}


.allContainerSchedule .MuiCardContent-root {
  padding: 0px;
}

.scheduleTitleDay{
  font-size: 18px;
  margin-right: 20px;
  color: black;
  margin-left: 50px;
  font-weight: 500;
  width:150px;
}
.scheduleExpand{
  
  /* border: solid 1px red; */
  /* height: 0; */
  padding: 30px;
  padding-left: 55px;
  margin-top: 10px;
  margin-bottom: -25px;
  background-color: rgb(236, 236, 236);
  /* animation: dropdown 3s; */
}
/* @keyframes dropdown {
  from {
    height:0;
    content: '';
  }
  to{
    height: auto;
  }
} */

.scheduleUpdateError{
  color: red;
}
.scheduleError{
  min-width:500px;
  color: red;
  margin-top: 13px;
  margin-right: 10px;
}