.articleEditContainer {
    /* border: solid 1px blue; */
    display: flex;
    padding-top: 0;
    /* gap: 20px; */
}

.createArticleAllContainer .MuiDialogTitle-root h2 {
    margin: 20px 0px 20px 20px;
    font-size: 16;
    text-transform: uppercase;
    font-weight: 400;
    color: #444d59;
}

.createArticleAllContainer .MuiDialogTitle-root {
    padding: 0;
    border-bottom: 1px solid #e7e7e7;
}

.createArticleAllContainer .MuiPaper-root {
    min-width: 422px;
    max-width: 422px;
}

.createArticleAllContainer .simple-form .MuiCardContent-root {
    width: 85%;
    font-weight: 600;
    color: #928e8e;
    font-size: 16;
}

.createArticleAllContainer .simple-form .articleEditContainer {
    width: inherit;
}

.createArticleAllContainer .simple-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.articleListAllContainer .MuiToolbar-root {
    align-items: flex-start;
    background-color: #efefef;
    justify-content: flex-start;
}

.articleListAllContainer .ContainerAction {
    margin-bottom: 25px;
    margin-top: 25px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.articleListAllContainer .MuiTableHead-root .MuiTableCell-root {
    padding-top: 20px;
    padding-bottom: 20px;
}

.articleListAllContainer .ContainerAction .titlePage {
    margin: 0;
}

.articleListAllContainer .MuiTableCell-root .MuiTypography-root {
    display: block;
}

.articleTitle {
    font-size: 15px;
    font-weight: bold;
    margin: 10px;
}

.articleBlockLast {
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    width: 800px;
    padding-right: 25px;
    padding: 10px;
}

.articleBlock {
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 10px;
}
.articleBlockFirst {
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    /* -webkit-box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.2); */
    width: 600px;
    padding: 10px;
    padding-left: 25px;
}

.articleEditContainer .MuiAccordion-root {
    width: auto;
    border: none;
    margin-left: 12px;
    margin-right: 12px;
}

.articleEditContainer .MuiAccordionSummary-root {
    margin-bottom: 5px;
}

.articleButtonAdd {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    /* width: 100%; */
    color: #3f7cc2;
    text-transform: uppercase;
    display: flex;
}

.articleButtonAdd span {
    margin-right: 15px;
    margin-left: 5px;
}

/* # # # # # # # # # # # IMAGES # # # # # # # # # # # # #  */

.articleImgDeleteContainer {
    overflow: inherit;
    display: flex;
    width: 100%;
}

.imgDeleteContainer {
    position: relative;
    display: flex;
}

.articleOneLine {
    display: flex;
    gap: 5px;
}

.articleImg {
    width: 150px;
    height: 100px;
    object-fit: cover;
    margin: 5px;
    border-radius: 5px;
    /* position: relative; */
    transition: all 0.2s ease;
}

.articleImgEmpty {
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: auto;
    background-color: rgb(235, 235, 235);
}

.articleImgOpacity {
    width: 150px;
    height: 100px;
    object-fit: cover;
    margin: 5px;
    border-radius: 5px;
    position: relative;
    opacity: 0.5;
    transition: all 0.2s ease;
    cursor: pointer;
}
.articleImgDel {
    position: absolute;
    top: 40px;
    left: 65px;
    animation: appear 300ms;
    cursor: pointer;
}
.articleImgDelTxt {
    position: absolute;
    cursor: pointer;
    top: 6px;
    left: 8px;
    font-weight: bold;
    animation: appear 300ms;
}

.articleImgNoShow {
    display: none;
}

.articlePlaceHolder {
    max-width: 450px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 1px rgb(123, 123, 123);
}

.articleImgPreview {
    width: 100%;
}

.articleButtonandTitle {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.articleImgCrop {
    width: 250px;
}

/* # # # # # # # # # # # OPTIONS # # # # # # # # # # # # #  */

.articleAddOptions {
    display: flex;
    margin-left: 12px;
    margin-right: 12px;
    /* width: 88%; */
    gap: 20px;
    align-items: center;
}

.articleEditContainer .articleAddOptions .MuiFormControl-root {
    width: 100%;
}

.articleEditContainer .buttonAddOption {
    margin-left: 12px;
    margin-right: 12px;
}

.articleEditContainer .buttonAddOption button {
    border: none;
    width: 100%;
    background-color: #358282;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 900;
}

.articleEditContainer .buttonAddOption button .MuiSvgIcon-root {
    font-size: 28px;
    margin-right: 15px;
}

.articleEditContainer .articlePlaceHolder {
    max-width: none;
}

.titleToolbar {
    font-size: 16px;
    font-weight: 400;
    color: #444d59;
    text-transform: uppercase;
}

.allContainerEditArticle .simple-form .MuiCardContent-root {
    padding: 0px;
}

.articleOptionsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.articleOptionsTitles {
    display: flex;
    justify-content: space-between;
}

.articleOptionTitle {
    flex: 0 0 auto;
    width: 90px;
    font-size: 12px;
    display: flex;
    justify-content: center;
}

.articleOptionLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.articleEditContainer .articleOptionLine .MuiTypography-body2 {
    width: 140px;
    text-align: center;
}

.articleEditContainer .articleOptionLine .articleEditNameAddOptionLabel {
    margin-right: 60px;
}

.articleEditContainer .articleOptionLine .MuiFormControl-root {
    width: 90px;
}

.articleEditContainer .articleOptionLine .checkBoxOptionArticleEdit {
}

.articleEditContainer .containerAccordionArticleEdit {
    width: 100%;
}

.articleListAllContainer .isInStockList {
    width: 40%;
    height: 50px;
    margin: auto;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.articleListAllContainer .isInStockList:hover {
    background-color: #accee2;
}

.articleEditContainer .MuiAccordionDetails-root {
    padding: 0;
}

.articleOneLine {
    display: flex;
    justify-content: space-between;
    /* border: 1px purple solid; */
    /* width: 400px; */
}

.articlePriceField {
    /* cursor: pointer; */
}

.articlePriceFieldSelected {
    background-color: rgb(185, 207, 185);
}

.articlePriceFieldToChange {
    background-color: rgb(207, 206, 185);
    animation: blinkMe 2s infinite;
}

@keyframes blinkMe {
    0% {
        background-color: rgb(237, 237, 237);
    }
    50% {
        background-color: white;
    }
    100% {
        background-color: rgb(231, 231, 231);
    }
}

.articleNote {
    font-size: 12px;
    margin-bottom: 15px;
}

.articlePreOrderTime {
    display: flex;
    height: 80px;
}

.articleMaxMinLine {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.articleRequired {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.articleRangeError {
    display: flex;
    justify-content: center;
    color: red;
    font-size: 14px;
}

.articleTextMaxMin {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.articleTime {
    margin-top: 15px;
}
.articleTimeContainerSchedule {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.articleTimeTitle {
    font-size: 1em;
    margin-right: 15px;
    color: black;
}

.articleOpenTimeContainer {
    margin-right: 15px;
}

.articleTimeAlreadyPresent {
    font-size: 14px;
    color: black;
}

.articleTimeIconContainer {
    /* border: 0.2px black solid;
    border-radius: 35px; */
    /* position: absolute; */
    /* right: 50px; */
    /* display: flex;
    justify-content: flex-end; */
}

.articleTimeIconContainer .MuiSvgIcon-root {
    cursor: pointer;
}
