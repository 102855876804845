.list-page .MuiPaper-root .MuiTableCell-root {
  text-align: center;
}

.pageDelivery .list-page .MuiPaper-root{
  height: min-content;
}

.list-page .MuiPaper-root .MuiTableCell-root .MuiTypography-root .paddingLeft{
padding-left: 5px;
text-overflow: ellipsis;
overflow: hidden !important;
line-break:anywhere;
}

.containerAllDeliveryList .MuiTable-root .MuiTableBody-root .MuiTableRow-root{
  background-color: #F9FAFE;
}

.containerAllDeliveryList .MuiTable-root .MuiTableBody-root{
  background-color: #F9FAFE;
}

.containerAllDeliveryList .MuiPaper-root .MuiTablePagination-root .MuiToolbar-regular{
  background-color: #F9FAFE;
}

.containerAllDeliveryList .MuiPaper-root .MuiToolbar-root{
  background-color: white;
}
.containerAllDeliveryList .MuiToolbar-root{
  background-color: #EFEFEF;
}

.defaultPageContainer {
  padding-left: 40px;
  padding-right: 40px;
}

.titlePage{
  /* margin-left: 15px; */
  margin-top: 25px;
  color: #324B4B;
  font-weight: 600;
  font-size: 18px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
}

.containerAllDeliveryList .MuiPaper-root{
height: min-content;
}