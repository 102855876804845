.dashboardContainer {
    background-color: #F9FAFE;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
}

.dashboardTitle {
    margin-top: 20px;
    font-size: 40px;
    color: white;
}
 .dashboardContainer img {
     width: 702px;
     height: 415px;
 }