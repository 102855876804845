.promoContainer {
    display: flex;
}
.promoContainer .promoCard .MuiTypography-root {
    color: #444D59;
    font-size: 16px;
    font-weight: 400;
}

.containerAllPromoList .MuiToolbar-root .MuiButtonBase-root{
    margin-right: 15px;
    padding: 0px;
}

.containerAllPromoList .titlePage{
    margin-bottom: 20px;
}
.promoCard {
    width: 400px;
    display: flex;
    flex-direction: column;
    border: solid 1px #E7E7E7;
    padding: 10px;
}
.saveButton{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    background-color: white;
}

.saveButton .MuiButtonBase-root{
    margin-right: 30px;
    background-color: #444D59;
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    padding-left: 48px;
    padding-right: 48px;
}
.promoTwoInputs {
    display: flex;
    height: 80px;
}

.saveButton .MuiToolbar-root {
    background-color: white;
}

.containerPromo{
    margin-right: 30px;
    margin-left: 30px;
}
