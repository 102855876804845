.containerpage {
  /* background-color: #e32626; */
  background-color: #EFEFEF;
  display: flex;
  padding: 10px;
  gap: 25px;
  justify-content: center;
  transition: all 0.3s ease;
  /* height: max-content; */
  /* min-height: 92vh; */
}

body {
  height: 100%;
  margin: 0;
}

#main-content {
  padding: 0;
}

.dndContainer .dndTitle {
  margin: 0;
  border-radius: 10px;
  color:#444D59 ;
  font-weight: 600;
  font-size: 16px;
  background-color: inherit;
  display: flex;
  justify-content: space-between;
  margin: 2px;
}
.dndContainer {
  margin-top: 20px;
  background-color: #DBDBDB;
  height: min-content;
  min-height: 100px;
  border-radius: 10px;
  border: 1px solid #DADADB;
  /* padding-bottom: 240px; */
  transition: all 0.3s ease;
  position: relative;
  /* overflow: hidden; */
}

.layout{
  background-color: #F9FAFE;
  height: 100%;
}

.containerAllOrder .MuiPaper-root{
box-shadow: none;
}

.containerAllOrder .dndContainer .dndItem {
  background-color: #FCFCFC;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.containerAllOrder .dndContainer .dndItem:hover {
  background-color: #F2F3F6;
}

.dndArrow {
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 26px solid rgb(203, 203, 203);
  position: absolute;
  top: 0px;
  right: -27px;
}

.dndSelected {
  transition: all 0.3s ease;
  background-color: #15A7A7;
}

.dndTitle {
  text-align: center;
  background-color: #e5e5e5;
  font-size: 15px;
  color: #474747;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px 0px 0px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  gap: 10px;
}
