/* FOR THE CARD */

.dndItem {
    width: 205px;
    /* height: 100px; */
    background-color: rgb(196, 237, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 5px;
    /* border: 1px solid rgb(218, 218, 218); */
    padding: 10px;
    gap: 5px;
    position: relative;
    -webkit-box-shadow: 8px 6px 11px -9px #000000;
    box-shadow: 8px 6px 11px -9px #000000;
}
.cardFirstLine {
    width: 210px;
    height: 20px;
    max-height: 20px;
    /* transform: translateX(-7px); */
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    align-items: center;
    margin-bottom: 7px;
}
.cardFirstLine > div {
    display: flex;
}

.dndItem .iconOrderCard {
    color: #e5e5e5;
    margin-right: 2px;
}

.cardLine {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.orderSuppressIcon {
    background-color: #dbdbdb;
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 0;
    position: absolute;
    top: 5px;
    right: 0;
}

.orderSuppressIconInside {
    padding: 3px;
}

.containerAllOrder .dndContainer .dndItemScheduleOrder {
    height: auto;
    border: 2px solid red;
}

.containerAllOrder .firstLineOrderCard {
    display: flex;
    flex-direction: row;
}

.containerAllOrder .precommandRed {
    color: red;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.containerAllOrder .precommandRed .MuiSvgIcon-root {
    color: red;
}

.divButton{
    width: 50px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    color: white;
    background-color: #358282;
    border-color: #358282;
    margin-top: 1px;
}